import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";
import Loading from "./components/Loading/Loading.js";
import { analyticService } from "./services/AnalyticService.js";

const Home = React.lazy(() => import("./components/Home/Home.js"));

const Fabricacion = React.lazy(() =>
  import("./components/Fabricacion/Fabricacion.js")
);

const Sellos = React.lazy(() => import("./components/Sellos/Sellos.js"));

const Mantenimiento = React.lazy(() =>
  import("./components/Mantenimiento/Mantenimiento.js")
);

const Reparacion = React.lazy(() =>
  import("./components/Reparacion/Reparacion.js")
);

const Centrales = React.lazy(() =>
  import("./components/Centrales/Centrales.js")
);

function App() {
  useEffect(() => {
    analyticService.initialize();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop />
        <Routes>
          <Route
            index
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            index
            path="/fabricacion"
            element={
              <Suspense fallback={<Loading />}>
                <Fabricacion />
              </Suspense>
            }
          />
          <Route
            index
            path="/sellos-hidraulicos"
            element={
              <Suspense fallback={<Loading />}>
                <Sellos />
              </Suspense>
            }
          />
          <Route
            index
            path="/mantenimiento"
            element={
              <Suspense fallback={<Loading />}>
                <Mantenimiento />
              </Suspense>
            }
          />
          <Route
            index
            path="/reparacion"
            element={
              <Suspense fallback={<Loading />}>
                <Reparacion />
              </Suspense>
            }
          />
          <Route
            index
            path="/centrales-hidraulicas"
            element={
              <Suspense fallback={<Loading />}>
                <Centrales />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
